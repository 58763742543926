export const environment = {
  production: false,
  stage: 'staging',
  companyId: 'krc4PsvgVuBKYck4mxen',
  amChartsKey: 'AM5C-5078-8816-8831-9926',
  firebaseConfig: {
    apiKey: 'AIzaSyCLMXshlqSvURQyJUn4kOksndIKOtnsXLk',
    authDomain: 'eule-staging.firebaseapp.com',
    projectId: 'eule-staging',
    storageBucket: 'eule-staging.appspot.com',
    messagingSenderId: '1060778636170',
    appId: '1:1060778636170:web:e77afc3b21ce237e9d8e73',
    measurementId: 'G-PHXHJ597XF',
  },
  stripeConfig: {
    publishableKey: 'pk_test_51QAzATGK6dhm77GnastTJdKRwU3xkmBLkcyvjAQtdFE8mTMXirsiJRjFd0cTWXgdO36cg5YMknCzHwEId8MfZU2X00V2mKNxjK',
  },
};
